import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
import InstantSearch from "vue-instantsearch";
import VueFeather from "vue-feather";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import excel from 'vue-excel-export'
import JsonExcel from "vue-json-excel";
import VueQRCodeComponent from 'vue-qr-generator'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMask from 'v-mask'

import firebase from "firebase/app";
// import VueFirebase from 'vue-firebase'
const config = {
    apiKey: "AIzaSyDZNo0jM8DVtar3XUYnU_366og3dhBC-c4",
    authDomain: "iservice-894e8.firebaseapp.com",
    projectId: "iservice-894e8",
    storageBucket: "iservice-894e8.appspot.com",
    messagingSenderId: "129123200738",
    appId: "1:129123200738:web:0618e94a6478660a3038bb",
    measurementId: "G-W84KLNSMJX"
};

// Vue.use(VueFirebase, {firebase: firebase, config});
firebase.initializeApp(config);



Vue.component('qr-code', VueQRCodeComponent)
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueSweetalert2);
Vue.use(VueQuillEditor, /* { default global options } */)

Vue.use(VueMask);
Vue.use(VueAxios, axios)
Vue.use(VueFeather);
Vue.use(VueSkycons, { color: "#1e88e5" });
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(excel)

new Vue({
    vuetify,
    store,
    router,
    render: (h) => h(App)
}).$mount("#app");
